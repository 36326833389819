import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  subheader: {
    color: 'rgba(0,0,0,0.67)',
    fontSize: 14,
    marginBottom: 20,
  },
});

export default styles;
